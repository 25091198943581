import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div class="deets">
        <p>a. ross cohen</p>
        <p><a href="https://www.linkedin.com/in/adam-ross-cohen-1983a662/">linkedin</a></p>
        <p><a href="https://github.com/a-ross-cohen">github</a></p>
      </div>
    )
  }
}

export default App;
